import { BryntumGrid } from '@bryntum/grid-react-thin';
import { Divider } from '@mui/material';
import PulseCheckBox from 'components/checkbox/checkbox';
import PulseDatePicker from 'components/pulse-date-picker/pulse-datepicker';
import PulseIcon from 'components/pulse-icons/pulse-icons';
import PulseInfoBar from 'components/pulse-info-bar/pulse-info-bar';
import PulseInput from 'components/pulse-input/pulse-input';
import PulseModalBase from 'components/pulse-modal-base/pulse-modal-base';
import PulseSelectBase from 'components/pulse-select/base/pulse-select-base';
import { PulseTimelineGanttProps } from 'components/pulse-timeline/pulse-timeline-types';
import PulseTooltip from 'components/pulse-tooltip/pulse-tooltip';
import { debounce } from 'lodash';
import { IconSizes } from 'pulse-commons/types';
import React, { FC, useCallback, useRef, useState } from 'react';
import TaskGrid from './components/task-grid';
import styles from './timeline-import-task-modal.module.scss';
import { AjaxStore, Model, ModelConfig } from '@bryntum/core-thin';
import { filterInCompleteTicket } from './helpers';
import { PulseSelectOptionType } from 'components/pulse-select/base/pulse-select-base-types';
import TimelineImportTaskWarningModal from '../TimelineImportTaskWarningModal/timeline-already-linked-task-warning-modal';
import TimelineExistedStartDateWarningModal from '../TimelineImportTaskWarningModal/timeline-existed-start-date-warning-modal';
import { format } from 'date-fns';
import PulseLoader from 'components/pulse-loader/pulse-loader';

export enum CheckBoxType {
  isShowCompletedTasks = 'isShowCompletedTasks',
  isUseStartDate = 'isUseStartDate',
  isChainTasks = 'isChainTasks',
}
export enum ModalType {
  taskImport = 'taskImport',
  existingStartDates = 'existingStartDates',
  tasksAlreadyLinked = 'tasksAlreadyLinked',
  loading = 'loading',
}

export type ImportTaskOptions = {
  isChainTasks: boolean;
  defaultStartDate?: string;
  duration?: number | string;
};

export type ImportTaskData = {
  id: string | number;
  name: string;
  startDate?: string;
  endDate?: string;
  parent: number;
};

export interface TimelineTaskImportModalProps {
  mockData?: boolean;
  project: PulseTimelineGanttProps['project'];
  onCloseModal?: () => void;
  onImported: (tasks: Array<ImportTaskData>, options: ImportTaskOptions) => void;
  timeline: PulseTimelineGanttProps['timeline'];
}

const durations = [...Array(31).keys()]
  .filter(option => {
    if (option > 0) {
      if (option > 10) {
        return option % 10 === 0;
      }
      return true;
    }
    return false;
  })
  .map(option => ({
    label: `${option} day${option > 1 ? 's' : ''}`,
    value: option,
  }));

const DURATIONS: {
  options: PulseSelectOptionType<Record<string, any>>[];
  defaultOptions: PulseSelectOptionType<Record<string, any>>;
} = {
  defaultOptions: {
    label: '3 days',
    value: 3,
  },
  options: durations,
};

const TimelineTaskImportModal: FC<TimelineTaskImportModalProps> = ({
  mockData,
  onCloseModal = () => {
    // Do nothing
  },
  project,
  onImported,
}) => {
  const gridRef = useRef<BryntumGrid>(null);
  const dataRef = useRef<Model[] | Partial<ModelConfig>[]>([]);
  const backupDataGrid = useRef<Record<string, any>[]>([]);
  const [tasksSelected, setTasksSelected] = useState<Record<string, any>[]>([]);

  const [checkBoxes, setCheckBoxes] = useState<Record<CheckBoxType, boolean>>({
    [CheckBoxType.isShowCompletedTasks]: false,
    [CheckBoxType.isUseStartDate]: false,
    [CheckBoxType.isChainTasks]: false,
  });
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [duration, setDuration] = useState(DURATIONS.defaultOptions);
  const [modalType, setModalType] = useState<ModalType>(ModalType.taskImport);
  const startDate = useRef<Date>(new Date());
  const { isShowCompletedTasks, isChainTasks, isUseStartDate } = checkBoxes;

  const handleUpdateTasksSelected = useCallback(
    selections => {
      setTasksSelected([...selections]);
    },
    [tasksSelected],
  );

  const filterCompleteTicketsForGrid = useCallback((allowFilterCompleteTickets: boolean) => {
    if (gridRef?.current) {
      const { instance: gridInstance } = gridRef?.current;
      const { store: gridAjaxStore } = gridInstance;

      allowFilterCompleteTickets
        ? (gridAjaxStore as AjaxStore).clearFilters()
        : (gridAjaxStore as AjaxStore).filter(filterInCompleteTicket);
    }
  }, []);

  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setCheckBoxes(checkbox => ({
      ...checkbox,
      [name]: checked,
    }));
    if (name === CheckBoxType.isShowCompletedTasks) {
      filterCompleteTicketsForGrid(checked);
    }
  };

  const debounceSearch = debounce(async searchKey => {
    if (gridRef?.current) {
      const { instance: gridInstance } = gridRef?.current;

      if (!gridInstance) {
        return;
      }

      const searchingParams = {
        'filter[quick_search]': searchKey,
      };

      gridInstance.record;

      const includedParam = {
        ...searchingParams,
      };

      const { store: gridAjaxStore } = gridInstance;
      await (gridAjaxStore as AjaxStore).load(includedParam);

      filterCompleteTicketsForGrid(checkBoxes[CheckBoxType.isShowCompletedTasks]);
    }
  }, 300);

  const handleSubmit = () => {
    const options: ImportTaskOptions = {
      isChainTasks: checkBoxes.isChainTasks,
    };

    if (checkBoxes.isUseStartDate) {
      options.defaultStartDate = format(startDate.current, 'yyyy-MM-dd');
      options.duration = duration.value;
    }

    const importTasks: Array<ImportTaskData> = [];
    // Re-map data to keep import hierarchy with task list.
    dataRef.current.forEach((it: any) => {
      if (tasksSelected.find(s => s.id === it.id)) {
        importTasks.push({
          id: it.id,
          name: it.title,
          startDate: it.startDate,
          endDate: it.endDate,
          parent: Number.isInteger(it.parent.parentIndex) ? it.parent.id : -1,
        });
      }
    });

    onImported(importTasks, options);
  };

  const handleProceed = () => {
    if (!gridRef.current?.instance) {
      return;
    }
    const isAlreadyLinked = tasksSelected.some(task => !!task?.linkItem);
    // const hasStartDate = tasksSelected.some(task => !!task?.startDate);
    // const isChain = checkBoxes.isChainTasks;
    dataRef.current = gridRef.current.instance.data || [];

    if (isAlreadyLinked) {
      setModalType(ModalType.tasksAlreadyLinked);
      return;
    }
    // if (isChain && hasStartDate) {
    //   setModalType(ModalType.existingStartDates);
    //   return;
    // }

    handleSubmit();
  };

  const render = () => {
    switch (modalType) {
      case ModalType.loading:
        return <PulseLoader isVisible={true} />;
      case ModalType.existingStartDates:
        return <TimelineExistedStartDateWarningModal onClose={onCloseModal} onProceed={handleSubmit} />;
      case ModalType.tasksAlreadyLinked:
        return <TimelineImportTaskWarningModal onClose={onCloseModal} onProceed={handleSubmit} />;
      default:
        return (
          <PulseModalBase
            onCancelClick={onCloseModal}
            onModalClose={onCloseModal}
            onConfirmClick={handleProceed}
            variant="jqconfirm"
            open={true}
            headerIconClasses="fal fa-file-import"
            DialogProps={{
              PaperProps: {
                // style inline because this css broken when use date picker
                style: {
                  maxWidth: 1000,
                  maxHeight: 'fit-content',
                },
              },
            }}
            classes={{
              dialog: {
                paper: styles['timeline-task-import-modal'],
              },
              root: [styles['timeline-task-import-modal__body']],
            }}
            PulseModalHeaderProps={{
              primaryText: 'IMPORT TASKS TO TIMELINE',
              classes: {
                root: styles['timeline-task-import-modal__header'],
                titlePrimary: styles['header-primary-text'],
              },
            }}
            PulseModalFooterProps={{
              classes: {
                root: [styles['timeline-task-import-modal__footer']],
                confirm: [styles['footer-btn']],
                cancel: [styles['footer-btn']],
              },
            }}
            footerConfirmText="Add Tasks to Timeline"
            footerConfirmDisabled={!gridRef?.current?.instance?.selectedRecords?.length}
            footerConfirmIconClasses="fal fa-plus"
          >
            <>
              <PulseLoader isVisible={isDataLoading} />
              <section data-testid="modal-body-section" className={styles['body__warning']}>
                <PulseInfoBar
                  infoType="warning"
                  classes={{
                    root: styles['info'],
                  }}
                >
                  <p>Use this tool to Import and Link Tasks to Timeline Items.</p>
                  <ul>
                    <li>
                      When you Import a Task, the Timings for the Task will be controlled by the linked Timeline Item.
                    </li>
                    <li>A Task can only be linked to a single Timeline Item.</li>
                    <li>
                      If any selected Task is linked to another Timeline Item, it will be un-linked from that Item and
                      re-linked to the new Timeline Item during import.
                    </li>
                    <li>
                      Once imported, Task dates will adjust automatically, considering non-working days and dependencies
                      defined in the Timelines.
                    </li>
                  </ul>
                </PulseInfoBar>
              </section>
              <section data-testid="modal-body-section" className={styles['body__toolbar']}>
                <PulseInput
                  label={''}
                  classes={{
                    root: styles['search-root'],
                    input: styles['search-root__input'],
                  }}
                  InputBaseProps={{
                    placeholder: 'Search fo Task...',
                    startAdornment: (
                      <PulseIcon
                        iconName=""
                        classes={{
                          root: styles['search-root__icon'],
                          icon: 'fal fa-search',
                        }}
                        size={IconSizes.lg}
                      />
                    ),
                    onChange: e => {
                      debounceSearch(e?.target?.value);
                    },
                  }}
                />
                <PulseCheckBox
                  checked={isShowCompletedTasks}
                  label="Show Completed Tasks"
                  name={CheckBoxType.isShowCompletedTasks}
                  showLabel={true}
                  labelPlacement="right"
                  labelClasses={styles['checkbox-label']}
                  onClick={handleChangeCheckBox}
                />
                <Divider orientation="vertical" />
                <PulseCheckBox
                  checked={isUseStartDate}
                  label="Set Start Date"
                  name={CheckBoxType.isUseStartDate}
                  showLabel={true}
                  labelPlacement="right"
                  labelClasses={styles['checkbox-label']}
                  onClick={handleChangeCheckBox}
                />
              </section>
              {isUseStartDate && (
                <section data-testid="modal-body-section-dateBar" className={styles['body__date-bar']}>
                  <div className={styles['date-picker']}>
                    <p className={styles['date-picker__label']}>
                      DEFAULT TASK START DATE <i className="fal fa-info-circle"></i>
                    </p>

                    <PulseDatePicker
                      values={{
                        startDate: startDate.current,
                      }}
                      onChange={({ values }) => {
                        if (values?.startDate) {
                          startDate.current = values?.startDate;
                        }
                      }}
                      DatepickerProps={{
                        DatePickerInputProps: {
                          fullWidth: true,
                        },
                        variant: 'input',
                        TippyProps: {
                          appendTo: document.body,
                        },
                      }}
                    />
                  </div>

                  <PulseSelectBase
                    dataStructure={null}
                    variant="select2"
                    value={duration}
                    options={DURATIONS.options}
                    insideModal
                    isMulti={false}
                    isClearable={false}
                    isSearchable={false}
                    classes={{
                      label: styles['select-label'],
                      root: styles['select-root'],
                    }}
                    changeHandler={(newDuration: PulseSelectOptionType<Record<string, any>>) => {
                      setDuration(newDuration);
                    }}
                    labelName="Default Task Duration"
                    labelIcon={{
                      icon: 'fal fa-info-circle',
                    }}
                  />
                  <div className={styles['date-bar__checkbox']}>
                    <PulseCheckBox
                      checked={isChainTasks}
                      label="Chain Tasks"
                      name={CheckBoxType.isChainTasks}
                      showLabel={true}
                      labelPlacement="right"
                      labelClasses={styles['checkbox-label']}
                      onClick={handleChangeCheckBox}
                    />
                    <PulseTooltip
                      TippyProps={{
                        content:
                          'When this option is enabled, tasks are set to run sequentially one after another. Deselect this option to have the task timings run concurrently.',
                        placement: 'top',
                      }}
                    >
                      <i className="fal fa-info-circle"></i>
                    </PulseTooltip>
                  </div>
                </section>
              )}
              <section data-testid="modal-body-section" className={styles['body__task-grid']}>
                <TaskGrid
                  ref={gridRef}
                  mockData={mockData}
                  selectionChange={handleUpdateTasksSelected}
                  project={project}
                  data={backupDataGrid.current}
                  onAfterRequest={data => {
                    backupDataGrid.current = data;
                    setIsDataLoading(false);
                  }}
                />
              </section>
            </>
          </PulseModalBase>
        );
    }
  };

  return <div data-testid="timeline-task-import-modal">{render()}</div>;
};

export default TimelineTaskImportModal;

import React, { ReactElement } from 'react';
import { PulseModalHeaderProps } from './pulse-modal-header-types';
import styles from './pulse-modal-header.module.scss';
import PulseIconButton from '../../../pulse-icon-button/pulse-icon-button';
import Icon from '../../../pulse-icons/pulse-icons';
import clsx from 'clsx';
import { IconSizes } from 'pulse-commons/types';

const PulseModalHeader: React.FunctionComponent<PulseModalHeaderProps> = (
  props: PulseModalHeaderProps,
): ReactElement => {
  const {
    additionalContent,
    allowClose = true,
    classes,
    isV3,
    closeButtonSize = IconSizes.lg,
    headerIconClasses,
    onCloseClick,
    primaryText = 'title',
    PulseIconButtonProps,
    secondaryText,
    itemId,
    closeButtonTooltip = 'Close modal',
    headerSecondaryIconClasses,
  } = props;

  console.log('classes', classes);

  const rootClasses = clsx(styles[`pulse-modal-header__root${isV3 ? 'V3' : ''}`], classes?.root);
  const titleContainer = clsx(styles[`pulse-modal-header__title--ctn${isV3 ? 'V3' : ''}`], classes?.titleContainer);
  const titleContainerText = clsx(
    styles[`pulse-modal-header__title--text-ctn${isV3 ? 'V3' : ''}`],
    classes?.titleContainerText,
  );
  const titlePrimary = clsx(styles[`pulse-modal-header__title-primary${isV3 ? 'V3' : ''}`], classes?.titlePrimary);
  const titleSecondary = clsx(
    styles[`pulse-modal-header__title-secondary${isV3 ? 'V3' : ''}`],
    classes?.titleSecondary,
  );
  const additionalContentClass = clsx(
    styles[`pulse-modal-header__additional-content${isV3 ? 'V3' : ''}`],
    classes?.additionalContent,
  );
  const closeBtn = clsx(styles[`pulse-modal-header__btn--close${isV3 ? 'V3' : ''}`], classes?.closeBtn);

  return (
    <div data-testid="pulse-modal-header__root" className={rootClasses}>
      <div className={titleContainer}>
        {headerIconClasses && (
          <Icon
            classes={{
              root: styles['pulse-modal-header__title-icon'],
              icon: headerIconClasses,
            }}
            size={IconSizes.lg}
            iconName=""
          />
        )}
        <div className={titleContainerText}>
          {isV3 ? <span className={titlePrimary}>{primaryText}</span> : <h2 className={titlePrimary}>{primaryText}</h2>}
          {isV3 ? (
            <div className={styles['pulse-modal-header__secondary-title-ctnV3']}>
              <i className={clsx([styles['pulse-modal-header__secondary-iconV3'], headerSecondaryIconClasses])} />
              <p className={titleSecondary}>{itemId}</p>
            </div>
          ) : (
            secondaryText && <p className={titleSecondary}>{secondaryText}</p>
          )}
        </div>
      </div>
      {additionalContent && <div className={additionalContentClass}>{additionalContent}</div>}
      {allowClose && (
        <PulseIconButton
          classes={{
            pulseIcon: {
              icon: clsx([
                styles[`pulse-modal-header__close-icon${isV3 ? 'V3' : ''}`],
                isV3 ? 'fa-solid fa-xmark' : 'fal fa-times',
              ]),
            },
            root: isV3 ? styles['pulse-modal-header__close-iconV3'] : [closeBtn],
          }}
          handleClick={onCloseClick}
          iconName={null}
          size={closeButtonSize}
          tooltip={closeButtonTooltip}
          {...PulseIconButtonProps}
        />
      )}
    </div>
  );
};

export default PulseModalHeader;

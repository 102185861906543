import React, { ReactNode } from 'react';
import { format } from 'date-fns';
import moment from 'moment';

export const formatDate = (date: string): string => {
  const dateFormat = moment(date).format(
    window?.pulse?.request?.countryShortDateFormat
      ? window?.pulse?.request?.countryShortDateFormat?.replace('m', 'M')?.replace('d', 'D')
      : 'MM-DD-YYYY',
  );

  return dateFormat;
};

const getFormattedDate = (dateValue: string) => {
  if (dateValue) {
    const formattedDate = formatDate(dateValue);
    return formattedDate;
  }
  return '';
};

export const DEFAULT_COLUMNS: Array<Partial<any>> = [
  {
    id: 'sequence',
    type: 'sequence',
    text: 'Sequence',
    width: 80,
    //order: 1,
    readOnly: true,
    enableCellContextMenu: false,
    enableHeaderContextMenu: false,
    showColumnPicker: false,
    draggable: false,
  },
  {
    id: 'id',
    editor: false,
    type: 'itemId' as any,
    field: 'id',
    text: 'Item ID',
    hidden: false,
    width: 80,
    //order: 2,
  },
  {
    id: 'name',
    type: 'name',
    field: 'name',
    text: 'Timeline items',
    editor: true,

    //  order: 3
  },
  {
    id: 'startdate',
    type: 'date',
    field: 'startDate',
    text: 'Start',
    hidden: false,
    renderer: ({ value }: { value: string }): string => {
      if (value) {
        return `${format(new Date(value), 'EE')} ${getFormattedDate(value)}`;
      }

      return getFormattedDate(value);
    },
    editor: {
      editable: false,
      autoExpand: true,
    },
  },
  {
    type: 'enddate',
    field: 'endDate',
    text: 'Finish',
    hidden: false,
    renderer: ({ value }: { value: string }): string => {
      if (value) {
        return `${format(new Date(value), 'EE')} ${getFormattedDate(value)}`;
      }

      return getFormattedDate(value);
    },
    editor: {
      editable: false,
      autoExpand: true,
    },
  },
  {
    id: 'duration',
    type: 'duration',
    instantUpdate: false,
    editor: {
      decimalPrecision: 2,
      keyStrokeChangeDelay: 500,
    },
  },
  {
    type: 'column',
    field: 'status',
    text: 'Status',
    width: 90,
    editor: false,
  },
  {
    type: 'resourceassignment',
    id: 'pulse-timeline-gantt-resource-editor',
    width: 60,
    showAvatars: true,
    editor: {
      type: 'assignmentfield',
      picker: {
        height: 350,
        width: 450,
        unitsColumn: {
          hidden: true,
        },
        id: 'pulse-timeline-gantt-resource-editor',
        resourceColumn: {
          filterable: {
            filterField: {
              placeholder: 'Search project team member...',
              triggers: {
                filter: {
                  cls: 'b-icon fal fa-search',
                  align: 'start',
                },
              },
            },
          },
        },
        features: {
          headerMenu: false,
          cellMenu: false,
          sort: 'name',
          filterBar: true,
        },
      },
    },
    text: 'Assigned Users',
  },
  {
    type: 'predecessor',
    editor: true,
    dependencyIdField: 'sequenceNumber',
    // don't remove here
    // renderer: ({ record }) => {
    //   const predecessors = record.predecessors.map(predecessor => {
    //     let label = predecessor.fromTask?.name || '';
    //     if (predecessor.lag) {
    //       const lagUnit = predecessor.lag > 1 ? `${predecessor.lagUnit}s` : `${predecessor.lagUnit}`;
    //       label = `${label} (+${predecessor.lag} ${lagUnit})`;
    //     }
    //     return label;
    //   });
    //   return predecessors.join('; ');
    // },
  },
  {
    type: 'column',
    field: 'ticket_id',
    text: 'Linked Task',
    hidden: false,
    draggable: true,
    editable: false,
    renderer: ({ record }: { record: any }): ReactNode => {
      const { linkedTasks } = record.data;
      if (!linkedTasks?.length) {
        return '';
      }
      return (
        <div className="pulse-linked-tasks-columns">
          {linkedTasks.map(ticket => (
            <a key={ticket.id} href={ticket?.raw?.ticketUrl} target="_blank" rel="noreferrer">
              {ticket.id}
            </a>
          ))}
        </div>
      );
    },
  },
  {
    type: 'predecessor',
    editor: true,
    dependencyIdField: 'sequenceNumber',
    // don't remove here
    // renderer: ({ record }) => {
    //   const predecessors = record.predecessors.map(predecessor => {
    //     let label = predecessor.fromTask?.name || '';
    //     if (predecessor.lag) {
    //       const lagUnit = predecessor.lag > 1 ? `${predecessor.lagUnit}s` : `${predecessor.lagUnit}`;
    //       label = `${label} (+${predecessor.lag} ${lagUnit})`;
    //     }
    //     return label;
    //   });
    //   return predecessors.join('; ');
    // },
  },
  {
    type: 'percentdone',
    field: 'percentDone',
    text: '% Progress',
    editor: {
      autoExpand: true,
    },
    showCircle: true,
    hidden: true,
    draggable: true,
  },
  {
    type: 'note',
    field: 'note',
    text: 'Notes',
    hidden: false,
    draggable: true,
  },
];

export const SESSION_STORAGE_COLUMNS_CONFIGS_KEY = 'ganttColumnsConfigs';
export const SESSION_STORAGE_GANTT_ZOOM_LEVEL = 'ganttZoomLevel';
export const SESSION_STORAGE_SUB_GRID_WIDTH_CONFIGS_KEY = 'ganttSubGridConfigs';
export const SESSION_STORAGE_SUB_GRID_CONFIGS_VISIBLE = 'isShowSchedule';
export const SESSION_STORAGE_GANTT_DISPLAY_CONFIG = 'ganttDisplayConfig';
export const SELECT_PAGE_SIZE = 10;

import React, { FC, PropsWithChildren, ReactElement } from 'react';
import { PulseDrawerProps } from './pulse-drawer-types';
import styles from './pulse-drawer.module.scss';
import Drawer from '@mui/material/Drawer';
import PulseModalHeader from '../pulse-modal-base/components/pulse-modal-header/pulse-modal-header';
import {
  ButtonSizes,
  ButtonVariants,
  Colors,
  IconSizes,
  Sizes,
  V3ButtonVariant,
  PulseChipType,
} from 'pulse-commons/types';
import clsx from 'clsx';
import PulseScrollbar from 'components/pulse-scrollbar/pulse-scrollbar';
import PulseModalFooter from 'components/pulse-modal-base/components/pulse-modal-footer/pulse-modal-footer';
import PulseXhrRequestStatus from 'components/pulse-modal-base/components/pulse-xhr-request-status/pulse-xhr-request-status';
import PulseChip from 'components/pulse-chip/pulse-chip';
import PulseButtonBase from 'components/pulse-button/base/pulse-button-base';
import PulseButtonV3Base from 'components/v3-components/components/pulse-button/pulse-button';

export const PulseDrawer: FC<PulseDrawerProps> = (props: PropsWithChildren<PulseDrawerProps>): ReactElement => {
  const {
    children,
    closeDrawerHandler,
    drawerFooterComponent,
    drawerHeaderComponent,
    PulseModalHeaderProps = {},
    PulseModalFooterProps = {},
    DrawerProps,
    ScrollbarProps,
    loading,
    PulseXhrRequestStatusProps,
    isV3Component = false,
  } = props;

  const { classes: DrawerClasses } = DrawerProps || {};

  const drawerClasses = {
    root: clsx(styles['pulse-drawer__root'], DrawerClasses?.root),
    paper: clsx(styles['pulse-drawer__paper'], DrawerClasses?.paper),
    paperAnchorRight: clsx(styles['pulse-drawer__paper--right'], DrawerClasses?.paperAnchorRight),
    paperAnchorLeft: clsx(styles['pulse-drawer__paper--left'], DrawerClasses?.paperAnchorLeft),
    paperAnchorTop: clsx(styles['pulse-drawer__paper--top'], DrawerClasses?.paperAnchorTop),
    paperAnchorBottom: clsx(styles['pulse-drawer__paper--bottom'], DrawerClasses?.paperAnchorBottom),
  };
  const { classes = {}, ...RestPulseModalHeaderProps } = PulseModalHeaderProps;
  let modalHeaderClasses = {};
  if (Array.isArray(classes)) {
    modalHeaderClasses = {
      root: clsx([styles['pulse-header__root'], ...classes]),
    };
  } else {
    modalHeaderClasses = {
      root: clsx([styles['pulse-header__root'], classes?.root]),
      titleContainer: clsx([styles['pulse-header__title-ctn'], classes?.titleContainer]),
      titleContainerText: classes?.titleContainerText,
      titlePrimary: clsx([styles['pulse-header__title-primary'], classes?.titlePrimary]),
      titleSecondary: clsx([styles['pulse-header__title-secondary'], classes?.titleSecondary]),
      additionalContent: classes?.additionalContent,
      closeBtn: clsx([styles['pulse-header__close-btn'], classes?.closeBtn]),
    };
  }

  const handleHeaderOnCloseClick = (): void => {
    closeDrawerHandler();
  };

  const loadingRootClasses = loading
    ? clsx(styles['pulse-drawer__loading'], styles['pulse-drawer__loading--active'])
    : styles['pulse-drawer__loading'];
  const loadingComponent = (
    <div className={loadingRootClasses}>
      <PulseXhrRequestStatus {...PulseXhrRequestStatusProps} />
    </div>
  );

  return (
    <Drawer
      data-testid="pulse-drawer__root"
      classes={drawerClasses}
      style={{
        zIndex: 1000,
      }}
      {...DrawerProps}
    >
      {loadingComponent}
      {!drawerHeaderComponent && (
        <PulseModalHeader
          classes={modalHeaderClasses}
          onCloseClick={handleHeaderOnCloseClick}
          closeButtonSize={IconSizes.lg}
          isV3={isV3Component}
          {...RestPulseModalHeaderProps}
        />
      )}
      {drawerHeaderComponent && drawerHeaderComponent}
      <PulseScrollbar {...ScrollbarProps}>{children}</PulseScrollbar>
      {!drawerFooterComponent && <PulseModalFooter {...PulseModalFooterProps} />}
      {drawerFooterComponent && drawerFooterComponent}
    </Drawer>
  );
};

export default PulseDrawer;

import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { PulseMenuButtonProps } from './pulse-menu-button-types';
import styles from './pulse-menu-button.module.scss';
import PulseButtonBase from '../base/pulse-button-base';
import { ButtonSizes, ButtonVariants, IconSizes } from 'pulse-commons/types';
import PulseMenu from 'components/pulse-menu/pulse-menu';
import { isV3Component } from 'components/v3/helpers/v3-components.helper';
import PulseButtonV3Base from 'components/v3-components/components/pulse-button/pulse-button';

export const PulseMenuButton = (props: PulseMenuButtonProps): ReactElement => {
  const { classes, children, menuButtonData, PulseButtonProps, TippyProps, designVariant } = props;

  const alignArrow = () => {
    const { icon, iconPosition, label } = PulseButtonProps || {};

    //label button empty then arrow is center button
    if (label === '') {
      return 'center';
    }

    if (icon !== undefined) {
      //have not icon then arrow center button
      //if have icon, will get position's icon but but Menu must be enough room to display
      return !icon ? 'center' : iconPosition ? iconPosition : 'left';
    }
    //default have not props then arrow is left
    //if have not enough to display then arrow is center button
    return iconPosition ? iconPosition : 'left';
  };

  const handleOnClick = () => false;

  const rootClasses = clsx(styles['pulse-menu-button__root'], classes?.root);

  if (isV3Component(designVariant)) {
    const { MenuV3ButtonProps, PulseV3ButtonProps } = props;
    return (
      <PulseMenu
        classes={{
          menuCtn: clsx(
            styles['pulse-menu-button__menu-ctn'],
            children && styles['pulse-menu-button__menu-ctn--custom-content'],
            classes?.menuCtn,
            (!TippyProps || TippyProps.arrow) && styles['pulse-menu-button__menu-ctn__v3--with-arrow'],
            styles['pulse-menu-button__menu-ctn__v3'],
          ),
        }}
        menuChildren={
          <>
            {!children &&
              MenuV3ButtonProps &&
              MenuV3ButtonProps.map(({ iconClasses, ...menuButtonProps }) => (
                <PulseButtonV3Base
                  key={menuButtonProps.label}
                  {...menuButtonProps}
                  iconClasses={{
                    ...iconClasses,
                    icon: [clsx(iconClasses?.icon), styles['pulse-menu-button__icon']],
                  }}
                />
              ))}
            {children}
          </>
        }
        TippyProps={{
          arrow: true,
          ...TippyProps,
        }}
        alignArrow={alignArrow()}
      >
        <div className={rootClasses}>
          <PulseButtonV3Base
            onClick={handleOnClick}
            icon
            iconClasses={{
              icon: [
                'fal fa-chevron-down',
                clsx(PulseButtonProps?.label === '' && styles['pulse-menu-button-action__icon']),
              ],
            }}
            {...PulseV3ButtonProps}
          />
        </div>
      </PulseMenu>
    );
  }

  return (
    <PulseMenu
      classes={{
        menuCtn: clsx(
          styles['pulse-menu-button__menu-ctn'],
          children && styles['pulse-menu-button__menu-ctn--custom-content'],
          classes?.menuCtn,
        ),
      }}
      menuChildren={
        <>
          {!children &&
            menuButtonData &&
            menuButtonData.map(({ classes, iconClasses, ...menuButtonProps }) => {
              const pulseMenuButtonClasses = clsx(styles['pulse-menu-button__menu-item'], classes);

              return (
                <PulseButtonBase
                  key={menuButtonProps.label}
                  {...menuButtonProps}
                  classes={[pulseMenuButtonClasses]}
                  iconClasses={{
                    ...iconClasses,
                    icon: [clsx(iconClasses?.icon), styles['pulse-menu-button__icon']],
                  }}
                  variant={ButtonVariants.text}
                />
              );
            })}
          {children}
        </>
      }
      TippyProps={{
        arrow: true,
        ...TippyProps,
      }}
      alignArrow={alignArrow()}
    >
      <div className={rootClasses}>
        <PulseButtonBase
          onClick={handleOnClick}
          icon
          iconClasses={{
            icon: [
              'fal fa-chevron-down',
              clsx(PulseButtonProps?.label === '' && styles['pulse-menu-button-action__icon']),
            ],
          }}
          iconSize={IconSizes.md}
          sizes={ButtonSizes.md}
          variant={ButtonVariants.outlined}
          {...PulseButtonProps}
        />
      </div>
    </PulseMenu>
  );
};

export default React.memo(PulseMenuButton);

import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { PulseOfficeViewerProps } from './pulse-office-viewer-types';
import styles from './pulse-office-viewer.module.scss';
import PulseUnsupportedViewer from '../pulse-unsupported-viewer/pulse-unsupported-viewer';

export const PulseOfficeViewer = (props: PulseOfficeViewerProps): ReactElement => {
  const { classes, PulseUnsupportedViewerProps } = props;

  return (
    <div data-testid="pulse-office-viewer" className={clsx(styles.pulseOfficeViewerRoot, classes?.root)}>
      {/*
       Disabling the following as per  
       [#[55142](https://beta.extranet-system.com/v2/passport/1260/tasklist/55142)] Design QA: Fix issues with Font Styling in Asset Detail Tabs + Broken Image Icon display
       [#[54780](https://beta.extranet-system.com/v2/passport/1416/tasklist/54780)] Hotfix: Rollback recent changes to displaying a Word or Powerpoint preview
       */}
      {/* <img data-testid="pulse-office-viewer__img" className={clsx(classes?.img)} src={src} alt={title} /> */}
      <PulseUnsupportedViewer
        classes={{ root: styles.overlay }}
        showOfficeOnline={window.pulse?.config?.wopi?.enabled}
        {...PulseUnsupportedViewerProps}
      />
    </div>
  );
};

export default PulseOfficeViewer;

import React, { ReactElement, forwardRef, Ref } from 'react';
import styles from './pulse-button.module.scss';
import { Colors, V3ButtonVariant } from 'pulse-commons/types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { PulseIconProps } from 'pulse-types/icon-types';
import PulseIcon from '../../../pulse-icons/pulse-icons';

export interface PulseButtonV3BaseProps {
  /**
   * Toggles the disabled attribute of the button
   * @default false
   */
  disabled?: boolean;
  /**
   * Whether to show/hide an icon to the left of label
   * @default false
   */
  icon?: boolean;
  /**
   * Text to display in the button
   * @default My Button
   */
  label?: string | null;
  /**
   * Color of the button which is a css class.
   *
   * Colors.default = ".pulse-default"
   * @default Colors.default
   */
  color?: Colors;
  /**
   * String or array of string classes.
   * This gets passed to PulseIcon classes.icon props
   */
  iconClasses?: PulseIconProps['classes'];
  /**
   * The material icon name
   * @default ""
   */
  iconName?: string;
  /**
   * Callback to execute when the button element of PulseButtonBase is clicked
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Show a loading spinner to the right of the label
   * @default false
   */
  showLoadMoreSpinner?: boolean;
  /**
   * Variant of the button to render. All the variants are controlled in ButtonVariants enum
   * @default ButtonVariants.primary
   */
  variant?: V3ButtonVariant;
  /**
   * Flag to add chevron icon to the right of the button
   * @default false
   */
  chevronType?: boolean;

  /**
   * Flag to restyles button for display in menu
   * @default false
   */
  asDropdownItem?: boolean;
}

/**
 * Use `PulseButtonBase` if you want to a display a button
 * in the UI
 *
 * Switch to Canvas tab and play around with the knobs until you are
 * happy with how the button look.
 *
 * You can then copy the *Story Source* and paste it in your
 * script file in PulseRepo
 */

export const PulseButtonV3Base = forwardRef<HTMLButtonElement, PulseButtonV3BaseProps>(
  (props: PulseButtonV3BaseProps, ref: Ref<HTMLButtonElement>): ReactElement => {
    const { t } = useTranslation();
    const {
      color = Colors.default,
      disabled = false,
      icon = false,
      iconClasses = {},
      iconName = '',
      label,
      onClick,
      showLoadMoreSpinner = false,
      variant = V3ButtonVariant.primary,
      chevronType = false,
      asDropdownItem = false,
      ...rest
    } = props;

    const pulseButtonIconClasses: PulseIconProps['classes'] = {
      icon: clsx(styles['pulse-button__icon'], iconClasses?.icon),
    };

    const pulseButtonChevronIconClasses: PulseIconProps['classes'] = {
      icon: clsx(styles['pulse-button__icon'], 'fa-solid fa-chevron-down'),
    };

    return (
      <div className={styles['pulse-button-ctn']}>
        <button
          data-testid="pulse-button"
          className={clsx([
            styles['pulse-button-v3__root'],
            styles[`pulse-button-v3__root__${variant}`],
            chevronType ? styles[`pulse-button-v3__root__chevron`] : '',
            !Boolean(label) ? styles['pulse-button-v3__root__icon-only'] : '',
            asDropdownItem && styles['pulse-button-v3__root--dropdown'],
          ])}
          onClick={onClick}
          disabled={disabled}
          type="button"
          ref={ref}
          {...rest}
        >
          {icon && <PulseIcon classes={pulseButtonIconClasses} iconName={iconName} />}
          {Boolean(label) && <span className={styles['pulse-button__label']}>{t(label as string)}</span>}
        </button>
        {chevronType && (
          <div
            className={clsx([
              styles['pulse-button-chevron-ctn'],
              styles[`pulse-button-chevron-ctn__${variant}`],
              styles[`pulse-button-chevron-ctn__chevron`],
            ])}
          >
            <PulseIcon classes={pulseButtonChevronIconClasses} iconName="" />
          </div>
        )}
      </div>
    );
  },
);

PulseButtonV3Base.displayName = 'PulseButtonV3Base';

export default PulseButtonV3Base;

import React, { FC, ReactElement } from 'react';
import PulseSelectBase from '../base/pulse-select-base';
import {
  PulseSelectBasePropTypes,
  PulseSelectPropTypes,
  PulseSelectDataStructureTypes,
  PulseSelectOptionType,
} from '../base/pulse-select-base-types';
import { ValueType } from 'react-select';
import PulseProjectOption from './components/pulse-project-option/pulse-project-option';
import isFunction from 'lodash/isFunction';
import { OptionProps } from 'react-select';
import PulseSuggestionSelect from 'components/pulse-suggestion-select';
import { METHODS } from 'pulse-api/base';

const JQ_PARAMS = `{
  "data": [
    .responses[] 
    | select(.requestId == \"project\") 
    | .body.data[] 
    | {
      "label": .attributes.jobTitle,
      "value": .attributes.jobId,
      "type": "project"
    }
  ]
}`;

const DATA_STRUCTURE: PulseSelectDataStructureTypes = {
  dataKey: 'data',
  isJsonApi: true,
  label: 'jobTitle',
  lastPage: 'meta.page.last_page',
  pageSize: 25,
  searchKey: 'or.jobtitle.jobextension.jobid',
  type: 'project',
  value: 'jobId',
};

const DEFAULT_PLACEHOLDER: PulseSelectBasePropTypes['placeholder'] = 'Filter by project name';
const DEFAULT_URL: PulseSelectBasePropTypes['url'] = `/v2/api/jobs`;

export const PulseProjectsSelect = (props: PulseSelectPropTypes & { showRecentSelected?: boolean }): ReactElement => {
  const {
    value = [],
    isMulti = false,
    labelName = 'Projects',
    placeholder = DEFAULT_PLACEHOLDER,
    url = DEFAULT_URL,
    requestFilterKey = 'projectId',
    valueChangeHandler,
    showRecentSelected,
    ...rest
  } = props;

  const handleValueChange: PulseSelectPropTypes['valueChangeHandler'] = (
    value: ValueType<PulseSelectOptionType>,
  ): void => {
    isFunction(valueChangeHandler) &&
      valueChangeHandler({
        [requestFilterKey]: value,
      });
  };

  if (showRecentSelected) {
    return (
      <PulseSuggestionSelect
        dataType="project"
        onChange={handleValueChange}
        PulseSelectGroupProps={{
          components: {
            Option: PulseProjectOption as FC<OptionProps<PulseSelectOptionType>>,
          },
          dataStructure: null,
          isMulti,
          labelName,
          placeholder,
          value,
          requestBatching: [
            {
              method: METHODS.GET,
              url,
              requestId: 'project',
              filterKey: 'or.jobtitle.jobextension.jobid',
              perPage: 15,
            },
          ],
          extraParams: {
            'filter[jobstatus]': 'active',
            jq: JQ_PARAMS,
          },
          ...rest,
        }}
      />
    );
  }

  return (
    <PulseSelectBase
      components={{
        Option: PulseProjectOption as FC<OptionProps<PulseSelectOptionType>>,
      }}
      dataStructure={DATA_STRUCTURE}
      isMulti={isMulti}
      labelName={labelName}
      placeholder={placeholder}
      url={url}
      value={value}
      changeHandler={handleValueChange}
      {...rest}
    />
  );
};

export default React.memo(PulseProjectsSelect);
